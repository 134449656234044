import { Head } from '@components/Head';
import { Layout } from '@components/Layout';
import LoadingSpinner from '@components/common/LoadingSpinner';
import { Navbar } from '@components/navbar';
import CartButton from '@components/navbar/CartButton';
import { Box, Flex, Grid, Text } from '@components/primitives';
import { useSignals } from '@preact/signals-react/runtime';
import { sCollections } from '@signals/collection';
import { sMarketplace } from '@signals/marketplace';
import { styled } from '@stitches.config';
import { CollectionCard } from '@v2/components/CollectionCard/CollectionCard';
import { LandingHeader } from '@v2/components/LandingHeader/LandingHeader';
import { NextPage } from 'next';
import Link from 'next/link';
import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

const StyledImg = styled('img');

const Home: NextPage = () => {
  useSignals();
  const isSmallDevice = useMediaQuery({ maxWidth: 905 });
  const marketplace = sMarketplace.value;
  const collections = sCollections.value;
  const isLoading = !collections;

  const logoImage = useMemo(() => {
    return (
      <StyledImg
        css={{
          width: isSmallDevice ? 72 : 118,
          height: isSmallDevice ? 72 : 118,
          borderRadius: '$radiusMedium',
          border: '2px solid $btnPrimaryColor',
        }}
        alt={`${marketplace?.name} marketplace`}
        src={marketplace?.imageLogo}
      />
    );
  }, [isSmallDevice, marketplace]);

  const navbarTopRight = useMemo(() => {
    return <CartButton />;
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Layout>
      <Head />
      <Box
        css={{
          maxWidth: 1522,
          m: 'auto',
        }}
      >
        <Navbar
          collectionSelectorVariant='fancy'
          noBackgroundOnScroll
          topRightSlot={navbarTopRight}
          sidebarColor='fancy'
          topPadding={false}
        />
      </Box>

      <LandingHeader />

      <Box
        css={{
          maxWidth: 1522,
          m: 'auto',
        }}
      >
        <Flex
          direction='column'
          css={{
            width: '100%',
            px: isSmallDevice ? '$3' : '$5',
            mt: isSmallDevice ? -32 : -64,
            position: 'relative',
            gap: isSmallDevice ? 32 : 60,
          }}
        >
          <Flex direction='column' css={{ gap: '$3' }}>
            {logoImage}
            <Text css={{ fontWeight: 500, fontSize: 24 }}>
              {marketplace?.name}
            </Text>
            <Text
              css={{ fontWeight: 500, fontSize: isSmallDevice ? 14 : 16 }}
              color='subtle'
            >
              {marketplace?.description}
            </Text>
            {!isSmallDevice && (
              <Flex css={{ gap: '$5', mt: '$3' }}>
                {Object.entries(marketplace?.socialLinks || {})?.map(
                  ([name, link]) => {
                    return (
                      <Link
                        key={name}
                        target='_blank'
                        href={link}
                        rel='noopener noreferrer'
                      >
                        <Text
                          color='link'
                          css={{ fontSize: 14, fontWeight: 500 }}
                        >
                          {name.charAt(0).toUpperCase() + name.slice(1)}
                        </Text>
                      </Link>
                    );
                  },
                )}
              </Flex>
            )}
          </Flex>
          <Flex direction='column' css={{ gap: isSmallDevice ? '$3' : 20 }}>
            <Text
              style='h2'
              color='brand'
              css={{
                textTransform: 'uppercase',
                fontWeight: 700,
                fontSize: 18,
              }}
            >
              Collections
            </Text>
            <Grid
              css={{
                gap: isSmallDevice ? '$3' : 20,
                gridTemplateColumns: '1fr',
              }}
            >
              {collections.map((collection, index) => (
                <CollectionCard
                  key={collection.name}
                  collection={collection}
                  orientation={index === 0 ? 'vertical' : 'horizontal'}
                />
              ))}
            </Grid>
          </Flex>
        </Flex>
      </Box>
      <Box css={{ pt: isSmallDevice ? 40 : 120 }}>
        <LandingHeader />
      </Box>
    </Layout>
  );
};

export default Home;
